import { render, staticRenderFns } from "./NewDashboard.vue?vue&type=template&id=8be224b4&"
import script from "./NewDashboard.vue?vue&type=script&lang=js&"
export * from "./NewDashboard.vue?vue&type=script&lang=js&"
import style0 from "./NewDashboard.vue?vue&type=style&index=0&id=8be224b4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAvatar,VCard,VCol,VContainer,VRow,VTab,VTabs,VTabsSlider})
